// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const SHOW_ERROR_MESSAGE = 'show_error_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGN_IN = 'sign_in';
export const SIGN_OUT = 'sign_out';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const USER_DATA = 'user_data';
export const USER_DATA_FAILED = 'user_data_failed';
export const USER_TOKEN_SET = 'user_token_set';
export const SET_ACTIVE_PAYMENT_ARRANGEMENT = 'set_active_payment_arrangement';
export const SET_ACTIVE_COMPANY = 'set_active_company';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

// Company / PaymentArrangement
export const GET_ACTIVE_COMPANY_DATA = 'get_active_company_data';
export const GET_COMPANIES = 'get_companies';
export const CREATE_COMPANY = 'create_company';
export const UPDATE_COMPANY = 'update_company';
export const DELETE_COMPANY = 'delete_company';
export const CHECK_DUPLICATED_CNPJ = 'check_duplicated_cnpj';

export const SHOW_UPDATE_COMPANY_FORM = 'show_update_company_form';
export const SHOW_CREATE_COMPANY_FORM = 'show_create_company_form';
export const SHOW_DUPLICATED_CNPJ_DIALOG = 'show_duplicated_cnpj_dialog';
export const SHOW_DELETE_COMPANY_DIALOG = 'show_delete_company_dialog';
export const HIDE_UPDATE_COMPANY_FORM = 'hide_update_company_form';
export const HIDE_CREATE_COMPANY_FORM = 'hide_create_company_form';
export const HIDE_DELETE_COMPANY_DIALOG = 'hide_delete_company_dialog';
export const HIDE_DUPLICATED_CNPJ_DIALOG = 'hide_duplicated_cnpj_dialog';

export const GET_PAYMENT_ARRANGEMENT_INVOICES = 'get_payment_arrangement_invoices';
export const GET_A_PAYMENT_ARRANGEMENT_INVOICE = 'get_a_payment_arrangement_invoice';
export const GET_PENDING_PAYMENT_ARRANGEMENT_INVOICES = 'get_pending_payment_arrangement_invoice';
export const UPDATE_PAYMENT_ARRANGEMENT_INVOICE = 'update_payment_arrangement_invoice';
export const SHOW_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG = 'show_invoice_settlement_and_payment_dialog';
export const HIDE_INVOICE_SETTLEMENT_AND_PAYMENT_DIALOG = 'hide_invoice_settlement_and_payment_dialog';
export const SHOW_INVOICE_SETTLEMENT_DIALOG = 'show_invoice_settlement_dialog';
export const HIDE_INVOICE_SETTLEMENT_DIALOG = 'hide_invoice_settlement_dialog';
export const SHOW_INVOICE_PAYMENT_DIALOG = 'show_invoice_payment_dialog';
export const HIDE_INVOICE_PAYMENT_DIALOG = 'hide_invoice_payment_dialog';

export const GET_COMPANY_SUMMARY = 'get_company_summary';

export const RESET_EVOM_PWD_RESULT = 'rest_evom_pwd_result';

export const GET_CREDITS = 'get_credits';
export const VOID_TOP_UP_TRANSACTION = 'void_top_up_transaction';
export const SHOW_VOID_TOP_UP_TRANSACTION_DIALOG = 'show_void_top_up_transaction_dialog';
export const HIDE_VOID_TOP_UP_TRANSACTION_DIALOG = 'hide_void_top_up_transaction_dialog';

export const GET_EXPENSES = 'get_expenses';

export const GET_WALLET_CONFIGS = 'get_wallet_configs';
export const UPDATE_APP_WALLET_APPLICATION_STATUS = 'updated_app_wallet_application_status';
export const UPDATE_WALLET_CONFIG = 'update_wallet_config';
export const UPDATE_WALLET_CONFIG_ITEM = 'update_wallet_config_item';
export const UPDATE_WALLET_CONFIG_ITEM_ATTACHMENT = 'updated_wallet_config_item_attachment';
export const UPDATE_WALLET_CONFIG_PAYMENT_ITEM = 'updated_wallet_config_payment_item';
export const UPDATE_PARAMETERIZATION_TABLE_STATE = 'updated_parameterization_table_state';
export const CLEAR_WALLET_PARAMETERIZATION_STATE = 'clear_wallet_parameterization_state'

export const GET_WALLET_REQUESTS = 'get_wallet_requests';
export const UPDATE_WALLET_REQUEST = 'update_wallet_requests';
export const SHOW_DENY_WALLET_FORM = 'show_deny_wallet_form';
export const HIDE_DENY_WALLET_FORM = 'hide_deny_wallet_form';
export const SHOW_APPROVE_WALLET_FORM = 'show_approve_wallet_form';
export const HIDE_APPROVE_WALLET_FORM = 'hide_approve_wallet_form';
export const SHOW_ATTACHMENT_DIALOG = 'show_attachment_dialog';
export const LOAD_ATTACHMENT_FILE = 'load_attachment_file';
export const HIDE_ATTACHMENT_DIALOG = 'hide_attachment_dialog';

export const GET_TOP_UP_INVOICES_COUNT_BY_STATUS = 'get_top_up_invoices_count_by_status';
export const GET_TOP_UP_INVOICES_COUNT_BY_DAY_AND_STATUS = 'get_top_up_invoices_count_by_day_and_status';

export const GET_EVOM_ACCOUNT_WALLETS = 'get_evom_account_wallets';

export const GET_POS_USERS = 'get_pos_users';
export const CREATE_POS_USER = 'create_pos_user';
export const UPDATE_POS_USER = 'update_pos_user';

export const SHOW_UPDATE_POS_USER_FORM = 'show_update_pos_user_form';
export const SHOW_CREATE_POS_USER_FORM = 'show_create_pos_user_form';
export const HIDE_UPDATE_POS_USER_FORM = 'hide_update_pos_user_form';
export const HIDE_CREATE_POS_USER_FORM = 'hide_create_pos_user_form';

export const GET_POS_COMPANIES = 'get_pos_companies';
export const CREATE_POS_COMPANY = 'create_pos_company';
export const UPDATE_POS_COMPANY = 'update_pos_company';
export const SHOW_CREATE_POS_COMPANY_FORM = 'show_create_pos_company_form';
export const HIDE_CREATE_POS_COMPANY_FORM = 'hide_create_pos_company_form';
export const SHOW_UPDATE_POS_COMPANY_FORM = 'show_update_pos_company_form';
export const HIDE_UPDATE_POS_COMPANY_FORM = 'hide_update_pos_company_form';

export const GET_AGENCY_USERS = 'get_agency_users';
export const CREATE_AGENCY_USER = 'create_agency_user';
export const UPDATE_AGENCY_USER = 'update_agency_user';
export const SHOW_UPDATE_AGENCY_USER_FORM = 'show_update_agency_user_form';
export const SHOW_CREATE_AGENCY_USER_FORM = 'show_create_agency_user_form';
export const HIDE_UPDATE_AGENCY_USER_FORM = 'hide_update_agency_user_form';
export const HIDE_CREATE_AGENCY_USER_FORM = 'hide_create_agency_user_form';
export const SHOW_RESET_AGENCY_USER_PASSWORD_DIALOG = 'show_reset_agency_user_password_dialog';
export const HIDE_RESET_AGENCY_USER_PASSWORD_DIALOG = 'hide_reset_agency_user_password_dialog';

export const GET_POS_TRANSACTIONS = 'get_pos_transactions';

export const GET_COMPANIES_SIMPLE_LIST = 'get_companies_simple_list';
export const GET_EVOM_ACCOUNTS_SIMPLE_LIST = 'get_evom_accounts_simple_list';

export const DEACTIVATE_INVOICE = 'deactivate_invoice';
export const SHOW_DEACTIVATE_INVOICE_DIALOG = 'show_deactivate_invoice_dialog';
export const HIDE_DEACTIVATE_INVOICE_DIALOG = 'hide_deactivate_invoice_dialog';

export const GET_COMPANY_EMPLOYEES = 'get_company_employees';
export const CREATE_COMPANY_EMPLOYEE = 'create_company_employee';
export const UPDATE_COMPANY_EMPLOYEE = 'update_company_employee';
export const DELETE_COMPANY_EMPLOYEE = 'delete_company_employee';
export const SHOW_UPDATE_COMPANY_EMPLOYEE_FORM = 'show_update_company_employee_form';
export const SHOW_CREATE_COMPANY_EMPLOYEE_FORM = 'show_create_company_employee_form';
export const HIDE_UPDATE_COMPANY_EMPLOYEE_FORM = 'hide_update_company_employee_form';
export const HIDE_CREATE_COMPANY_EMPLOYEE_FORM = 'hide_create_company_employee_form';
export const GET_TOKENS = 'get_tokens';
export const SHOW_QR_CODE = 'show_qr_code';
export const SHOW_BATCH_QR_CODE = 'show_batch_qr_code';
export const HIDE_QR_CODE = 'hide_qr_code';
export const HIDE_BATCH_QR_CODE = 'hide_batch_qr_code';
export const GENERATE_TOKEN = 'generate_token';
export const BATCH_GENERATE_TOKEN = 'batch_generate_token';
export const UPDATE_TOKEN = 'update_token';
export const SHOW_UPTADE_TOKEN_DIALOG = 'show_update_token_dialog';
export const HIDE_UPDATE_TOKEN_DIALOG = 'hide_update_token_dialog';
export const FETCHING_TOKENS = 'fetching_tokens';

export const GET_COMPANY_EMPLOYEES_GROUP = 'get_company_employees_group';
export const CREATE_COMPANY_EMPLOYEES_GROUP = 'create_company_employees_group';
export const UPDATE_COMPANY_EMPLOYEES_GROUP = 'update_company_employees_group';
export const DELETE_COMPANY_EMPLOYEES_GROUP = 'delete_company_employees_group';
export const SHOW_CREATE_COMPANY_EMPLOYEES_GROUP_FORM = 'show_create_company_employees_group_form';
export const HIDE_CREATE_COMPANY_EMPLOYEES_GROUP_FORM = 'hide_create_company_employees_group_form';
export const SHOW_UPDATE_COMPANY_EMPLOYEES_GROUP_FORM = 'show_update_company_employees_group_form';
export const HIDE_UPDATE_COMPANY_EMPLOYEES_GROUP_FORM = 'hide_update_company_employees_group_form';
export const SHOW_DELETE_COMPANY_EMPLOYEES_GROUP_FORM = 'show_delete_company_employees_group_form';
export const HIDE_DELETE_COMPANY_EMPLOYEES_GROUP_FORM = 'hide_delete_company_employees_group_form';

export const SHOW_RESET_USER_PASSWORD_DIALOG = 'show_reset_user_password_dialog';
export const HIDE_RESET_USER_PASSWORD_DIALOG = 'hide_reset_user_password_dialog';

export const GET_USERS = 'get_users';
export const CREATE_USER = 'create_user';
export const UPDATE_USER = 'update_user';

export const SHOW_UPDATE_USER_FORM = 'show_update_user_form';
export const SHOW_CREATE_USER_FORM = 'show_create_user_form';
export const HIDE_UPDATE_USER_FORM = 'hide_update_user_form';
export const HIDE_CREATE_USER_FORM = 'hide_create_user_form';

export const GET_COMPANY_INVOICES = 'get_company_invoices';
export const GET_COMPANY_INVOICES_PENDING = 'get_company_invoices_pending';

export const GET_SYSTEM_PROPERTY = 'get_system_property';
export const GET_ENABLE_DISCOUNT_POLICY_SYSTEM_PROPERTIES = 'get_enable_discount_policy_system_properties';

export const GET_FRESHWORKS_CONFIGURATION = 'get_freshworks_configuration';

export const GET_EVOM_ACCOUNT_DETAILS = 'get_evom_account_details';
export const SET_EVOM_ACCOUNT = 'set_evom_account';
export const EVOM_ACCOUNT_DETAILS_UPDATE_WALLET = 'evom_account_details_update_wallet';
export const EVOM_ACCOUNT_DETAILS_UPDATE_WALLET_STATUS = 'evom_account_details_update_wallet_status';
export const EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_WALLET_FORM = 'evom_account_details_show_update_wallet_form';
export const EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_WALLET_FORM = 'evom_account_details_hide_update_wallet_form';
export const EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_WALLET_STATUS_FORM = 'evom_account_details_show_update_wallet_status_form';
export const EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_WALLET_STATUS_FORM = 'evom_account_details_hide_update_wallet_status_form';
export const EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_COMPANY_WALLET_TYPE_FORM = 'evom_account_details_show_update_company_wallet_type_form';
export const EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_COMPANY_WALLET_TYPE_FORM = 'evom_account_details_hide_update_company_wallet_type_form';
export const EVOM_ACCOUNT_DETAILS_SHOW_RETRIEVE_WALLET_BALANCE_DIALOG = 'evom_account_details_show_retrieve_wallet_balance_dialog';
export const EVOM_ACCOUNT_DETAILS_HIDE_RETRIEVE_WALLET_BALANCE_DIALOG = 'evom_account_details_hide_retrieve_wallet_balance_dialog';
export const EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_ALLOWED_ROUTES_DIALOG = 'evom_account_details_show_update_allowed_routes_dialog';
export const EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_ALLOWED_ROUTES_DIALOG = 'evom_account_details_hide_update_allowed_routes_dialog';
export const GET_EVOM_ACCOUNT_BALANCE = 'get_evom_account_balance';
export const GET_EVOM_ACCOUNT_LAST_TOP_UP_DATA = 'get_evom_account_last_top_up_data';
export const UPDATE_EVOM_ACCOUNT = 'update_evom_account';
export const UPDATE_EVOM_ACCOUNT_REQUEST = 'update_evom_account_request';
export const UPDATE_EVOM_ACCOUNT_REQUEST_FAILURE = 'update_evom_account_request_failure';
export const START_EVOM_ACCOUNT_UPDATE = 'start_evom_account_update';
export const CANCEL_EVOM_ACCOUNT_UPDATE = 'cancel_evom_account_update';
export const SHOW_ADD_EVOM_ACCOUNT_AUTH_PROVIDER_FORM = 'show_add_evom_account_auth_provider_form';
export const HIDE_ADD_EVOM_ACCOUNT_AUTH_PROVIDER_FORM = 'hide_add_evom_account_auth_provider_form';
export const ADD_EVOM_ACCOUNT_AUTH_PROVIDER = 'add_evom_account_auth_provider';
export const ADD_EVOM_ACCOUNT_AUTH_PROVIDER_REQUEST = 'add_evom_account_auth_provider_request';
export const ADD_EVOM_ACCOUNT_AUTH_PROVIDER_REQUEST_FAILURE = 'add_evom_account_auth_provider_request_failure';
export const SYNC_EVOM_ACCOUNT_AUTH_PROVIDERS = 'sync_evom_account_auth_providers';
export const SYNC_EVOM_ACCOUNT_AUTH_PROVIDERS_REQUEST = 'sync_evom_account_auth_providers_request';
export const SYNC_EVOM_ACCOUNT_AUTH_PROVIDERS_REQUEST_FAILURE = 'sync_evom_account_auth_providers_request_failure';
export const GET_COMPANY_STATEMENT = 'get_company_statement';

export const SHOW_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG = 'show_import_invoice_receipt_attachment_dialog';
export const HIDE_IMPORT_INVOICE_RECEIPT_ATTACHMENT_DIALOG = 'hide_import_invoice_receipt_attachment_dialog';

export const SEND_EVOM_RESET_PASSWORD_EMAIL = 'send_evom_reset_password_email';
export const SEND_EVOM_RESET_PASSWORD_EMAIL_REQUEST = 'send_evom_reset_password_email_request';
export const SEND_EVOM_RESET_PASSWORD_EMAIL_FAILURE = 'send_evom_reset_password_email_failure';

export const SHOW_REVIEW_RECEIPT_DIALOG = 'show_review_receipt_dialog';
export const HIDE_REVIEW_RECEIPT_DIALOG = 'hide_review_receipt_dialog';